import React, { SVGProps } from "react"

export default ({ fill = "#ffffff" }: SVGProps<SVGGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <g
      fill={fill}
    >
      <path d="m 171.52715,15.576691 c -31.0674,0 -56.74175,23.877396 -59.61649,54.212765 L 69.412979,132.79997 c -7.391436,0.19485 -14.327155,2.23453 -20.390804,5.66305 L 8.8392855,122.06497 v 60.21901 l 19.8721575,8.11133 c 5.747498,17.64145 22.353354,30.46609 41.868456,30.46609 23.057508,0 42.064961,-17.892 43.878191,-40.49561 l 60.19615,-45.05653 c 31.54335,-1.63798 56.77478,-27.88503 56.76399,-59.822428 0.0137,-32.988821 -26.90227,-59.910141 -59.89108,-59.910141 z m 0,16.264592 c 24.19602,0 43.63256,19.445723 43.62267,43.641742 v 0.0039 c 0.0117,24.196017 -19.42665,43.645525 -43.62267,43.645525 -24.19598,0 -43.63255,-19.449508 -43.62265,-43.645525 v -0.0039 c -0.0117,-24.196019 19.42667,-43.641742 43.62265,-43.641742 z m 0,7.550737 c -19.9007,0 -36.08821,16.190809 -36.08335,36.091005 -0.006,19.90215 16.18122,36.094765 36.08335,36.094795 19.90073,0 36.08822,-16.190798 36.08337,-36.090988 C 207.61622,55.584684 191.4293,39.39202 171.52715,39.39202 Z M 70.579899,144.73242 c 17.789157,0 32.081411,14.29385 32.075401,32.083 v 0.003 c 0.006,17.78918 -14.286244,32.08681 -32.075401,32.08681 -9.92002,0 -18.743586,-4.45306 -24.616161,-11.47102 l 8.195227,3.34444 c 16.572117,6.76315 35.042001,1.16845 40.979904,-13.38158 5.937931,-14.55005 -3.341978,-31.47487 -19.914116,-38.238 l -9.766396,-3.98512 c 1.671411,-0.26616 3.372304,-0.44236 5.121542,-0.44236 z" />
    </g>
  </svg>
)
