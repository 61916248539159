import React, { MutableRefObject } from "react"
import { css } from "@emotion/core"

export interface WrapperProps extends React.AllHTMLAttributes<HTMLDivElement>{
  margin?: string
  padding?: string
}

export default React.forwardRef(({ margin, padding, children, ...rest }: WrapperProps, ref: MutableRefObject<unknown>) => (
  <section
    ref={ref}
    css={css`
      max-width: 1096px;
      box-sizing: border-box;
      padding: ${ padding ? padding : `initial`};
      margin: ${margin
        ? css`
            ${margin} auto 0 auto;
          `
        : css`0 auto;`};
    `}
    { ...rest }
  >
    {children}
  </section>
))