import React from "react"
import { GatsbyLinkProps } from "gatsby"
import Link from "./Link"
import { css } from "@emotion/core"
import { getIcon } from "./icons"

export interface ButtonLinkProps extends GatsbyLinkProps<unknown> {
  icon?: React.ReactNode
  color?: string
  iconColor?: string
  background?: string
  iconSize?: number
  subtitle?: string
  disabled?: boolean
}

export const style = ({
  background,
  color,
}: {
  background: string
  color: string
}) => css`
  padding: 1em;
  background: ${background};
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${color};
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none;
  @media print {
    display: none;
  }
`

export default ({
  icon,
  color = "#ffffff",
  iconColor,
  background = "#2bb1a4",
  iconSize = 2.5,
  children,
  className,
  disabled,
  subtitle,
  ...rest
}: ButtonLinkProps) => {
  const Component = icon
    ? typeof icon === "string"
      ? React.createElement(getIcon(icon)?.Component || "span", {
          fill: iconColor || color,
        })
      : typeof icon === "function"
      ? React.createElement(icon, { fill: iconColor || color })
      : icon
    : false

  return (
    <Link
      {...rest}
      css={style({ background, color })}
      className={className}
      disabled={disabled}
    >
      {Component && (
        <span
          css={css`
            height: ${iconSize}em;
            width: ${iconSize}em;
            display: inline-block;
            margin-right: 0.6em;
          `}
        >
          {Component}
        </span>
      )}
      <div css={css`
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}>
        <span>{children}</span>
        {subtitle && (
          <span
            css={css`
              font-size: 0.7em;
              display: block;
            `}
          >
            <span>
              {subtitle}
            </span>
          </span>
        )}
      </div>
    </Link>
  )
}
