import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export type SEOMeta = {
  name: string
  content: string
} | {
  property: string
  content: string
}

export interface SEOProps {
  description?: string,
  lang?: string,
  meta?: SEOMeta[],
  image?: string,
  title: string,
  imageAlt?: string
}

interface SEOMetaArray extends Array<SEOMeta|false>{}

const key = (item:SEOMeta) => "name" in item ? item.name : item.property

const removeDoubles = (arr:SEOMetaArray) => {
  const index = {}
  const ret: SEOMeta[] = []
  const { length } = arr
  let i = 0;
  while(i < length){
    const item = arr[i++]
    if(!item){ continue }
    const name = key(item)
    if(index[name]){ continue; }
    index[name] = true
    ret.push(item)
  }
  return ret
}

const objToProps = (prefix:string, key:'name'|'property', props:Record<string,string>) => Object.keys(props).map( tag => ({
  [key]:`${prefix}:${tag}`,
  content: props[tag]
}))

const SEO = ({ description = '', image, lang = 'en', meta = [], title, imageAlt }:SEOProps) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaTitle = (title ? title + ' | ' : '') + site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImage = site.siteMetadata.url + (image || site.siteMetadata.image)
  const metaImageAlt = imageAlt || `Chris, Transmogrify's hero, shoots a green slimey enemy`

  const metaTwitter = objToProps('twitter', 'name', {
    title: metaTitle,
    card: 'summary',
    description: metaDescription,
    image: metaImage,
    'image:alt':metaImageAlt,
    site: `TheTransmogrify`,
  }) 

  const openGraphTags = objToProps('og', 'property', {
    title: metaTitle,
    type: 'website',
    description: metaDescription,
    image: metaImage,
    'image:alt':metaImageAlt,
  })

  const _meta = removeDoubles([
    {
      name: `description`,
      content: metaDescription,
    },
    ...openGraphTags,
    ...metaTwitter
  ].concat(meta) as SEOMetaArray)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={_meta}
    >
    </Helmet>
  )
}

export default SEO
