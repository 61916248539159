// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bug-report-tsx": () => import("./../src/pages/bug-report.tsx" /* webpackChunkName: "component---src-pages-bug-report-tsx" */),
  "component---src-pages-demos-tsx": () => import("./../src/pages/demos.tsx" /* webpackChunkName: "component---src-pages-demos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presskit-mdx": () => import("./../src/pages/presskit.mdx" /* webpackChunkName: "component---src-pages-presskit-mdx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

