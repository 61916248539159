import React, { SVGProps } from "react"

export default ({ fill="#ffffff" }: SVGProps<SVGGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g
      transform="matrix(0.88629929,0,0,0.88629929,1.0677975,0.84745765)"
      fill={fill}
    >
      <path d="m 18.7826,16.196925 c 0,-0.969 -0.2803,-1.9035 -0.876,-2.7341 l -2.2427,-3.0802 2.2427,-3.08015 c 0.5957,-0.79601 0.876,-1.76505 0.876,-2.7341 0,-2.56106 -2.1376,-4.533766 -4.6606,-4.533766 -1.4718,0 -2.9435,0.726786 -3.8546,1.972706 L 9.14601,3.530105 C 8.7255,1.488185 6.93835,0 4.66061,0 2.03245,0 0,2.076535 0,4.637585 v 11.59394 c 0,2.5611 2.06749,4.6376 4.66061,4.6376 2.2427,0 3.99481,-1.3843 4.45036,-3.3916 l 0.98113,1.3843 c 0.9111,1.3152 2.418,2.0073 3.8897,2.0073 2.6632,0.0346 4.8008,-2.1111 4.8008,-4.6722 z" />
    </g>
  </svg>
)
