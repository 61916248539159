import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { css } from "@emotion/core"

export interface LinkProps extends GatsbyLinkProps<unknown> {
  disabled?: boolean
  tabIndex?: number
}

export const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  download,
  disabled,
  tabIndex,
  ...other
}: LinkProps) => {
  const scroll = /^#/.test(to)
  if (scroll) {
    to = "/" + to
  }
  const internal = /^\/(?!\/)/.test(to)
  const additionalProps = {
    css: disabled
      ? css`
          pointer-events: none;
          cursor: pointer;
          opacity: 0.7;
        `
      : null,
    tabIndex: tabIndex ? tabIndex : disabled ? -1 : undefined,
    disabled: disabled ? `disabled` : null,
  }
  if (internal && !download) {
    return (
      <GatsbyLink
        to={to}
        tabIndex={tabIndex}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...additionalProps}
        {...(other as any)}
      >
        {children}
      </GatsbyLink>
    )
  }
  if (download) {
    return <a href={to} download={true} {...additionalProps} {...other}>
      {children}
    </a>
  }
  const { ref: _, ...rest } = other
  return (
    <OutboundLink
      href={to}
      target="_blank"
      {...(additionalProps as any)}
      {...(rest as any)}
    >
      {children}
    </OutboundLink>
  )
}
export default Link
