import React from "react"
import Modal from "./Modal"
import { css } from "@emotion/core"

interface Props {
  title?: string
  label?: string
  children: React.ReactNode
}

export default ({ title = "Why?", label = title, children }: Props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = () => setIsOpen(true)

  const closeModal = () => setIsOpen(false)

  return (
    <span>
      <a
        css={css`
          text-decoration: underline;
          font-size: 0.9em;
          cursor: pointer;
        `}
        onClick={openModal}
      >
        {title}
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={title}
        isSmall
      >
        <div>
          {title && (
            <h2
              css={css`
                color: #000;
              `}
            >
              {title}
            </h2>
          )}
          <div
            css={css`
              color: #000;
            `}
          >
            {children}
          </div>
        </div>
      </Modal>
    </span>
  )
}
