import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

type UrlProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export default () => {
  const { site, file } = useStaticQuery<UrlProps>(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { eq: "transmogrify-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Link
      css={css`
        display: inline-block;
        width: 100%;
        text-align: center;
      `}
      to="https://www.playtransmogrify.com"
    >
      <Img
        fluid={file.childImageSharp.fluid}
        css={css`
          max-width: 100%;
          height: auto;
        `}
        alt={site.siteMetadata.title}
      />
    </Link>
  )
}
