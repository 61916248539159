import React, { SVGProps } from "react"

export default ({ fill = "#ffffff" }: SVGProps<SVGGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 400 400">
    <g
      fill={fill}
      transform="matrix(1.4881356,0,0,1.4881356,-97.627119,-97.637385)"
    >
      <path d="m 153.62,301.59 c 94.34,0 145.94,-78.16 145.94,-145.94 0,-2.22 0,-4.43 -0.15,-6.63 A 104.36,104.36 0 0 0 325,122.47 102.38,102.38 0 0 1 295.54,130.54 51.47,51.47 0 0 0 318.09,102.17 102.79,102.79 0 0 1 285.52,114.62 51.34,51.34 0 0 0 198.11,161.4 145.62,145.62 0 0 1 92.4,107.81 51.33,51.33 0 0 0 108.28,176.28 50.91,50.91 0 0 1 85,169.86 c 0,0.21 0,0.43 0,0.65 a 51.31,51.31 0 0 0 41.15,50.28 51.21,51.21 0 0 1 -23.16,0.88 51.35,51.35 0 0 0 47.92,35.62 102.92,102.92 0 0 1 -63.7,22 104.41,104.41 0 0 1 -12.21,-0.74 145.21,145.21 0 0 0 78.62,23" />
    </g>
  </svg>
)
