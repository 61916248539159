import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import Img, { FluidObject } from "gatsby-image"
import Modal from "./Modal"

type UrlProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allFile: {
    edges: {
      node: {
        base: string
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }[]
  }
}

export default ({ limit = 0 }) => {
  const {
    allFile: { edges },
  } = useStaticQuery<UrlProps>(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "presskit" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const images = (limit ? edges.slice(0, limit) : edges).map(
    ({
      node: {
        base,
        childImageSharp: { fluid },
      },
    }) => ({ ...fluid, name: base })
  )
  const [selectedImage, setSelectedImage] = useState(-1)

  const closeModal = () => setSelectedImage(-1)
  const isOpen = selectedImage > -1

  const currentImage = images[selectedImage]

  return (
    <div
      css={css`
        display: inline-block;
        width: 100%;
        text-align: center;
        @media print {
          display: none;
        }
      `}
    >
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Image"
      >
          {currentImage && (
            <Img
              key={currentImage.name}
              fluid={currentImage}
              css={css`
                width: 100%;
                height: auto;
                box-sizing: border-box;
                display: inline-block;
              `}
              alt={currentImage.name.replace(/\.\w\w\w$/, "")}
            />
          )}
      </Modal>
      {images.map((fluid, index) => (
        <div
          css={css`
            cursor: pointer;
            display: block;
            width: 49%;
            height: auto;
            box-sizing: border-box;
            margin: 0.5%;
            display: inline-block;
            transition: transform .3s ease-out;
            z-index: 0;
            &:hover{
              transition: transform .2s cubic-bezier(.75,-0.5,0,1.75);
              transform: scale(1.1);
              z-index: 1
            }
          `}
          key={fluid.name}
          onClick={() => setSelectedImage(index)}
        >
          <Img
            fluid={fluid}
            css={css`
              width: 100%;
              height: auto;
              box-sizing: border-box;
              display: inline-block;
            `}
            alt={fluid.name.replace(/\.\w\w\w$/, "")}
          />
        </div>
      ))}
    </div>
  )
}
