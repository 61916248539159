import Discord from "./Discord"
import Download from "./Download"
import Kickstarter from "./Kickstarter"
import News from "./News"
import Steam from "./Steam"
import Twitter from "./Twitter"
import { FunctionComponent } from "react"
import SteamFull from "./SteamFull"
//import DiscordFull from "./DiscordFull"

export interface IconSettings {
  name: string
  label: string
  url: string
  Component: FunctionComponent<any>
  menus: string[]
}
const icons = [
  {
    name: "Discord",
    url: "https://discordapp.com/invite/5AeaZxX",
    Component: Discord,
    menus: ["main", "home"],
  },
  {
    name: "Steam",
    url: "https://store.steampowered.com/app/740310/Transmogrify/",
    Component: Steam,
    menus: ["main"],
  },
  {
    name: "Steam-Full",
    label: "Buy Now on Steam",
    url: "https://store.steampowered.com/app/740310/Transmogrify/",
    Component: SteamFull,
    menus: ["buttons"],
  },
  {
    name: "Demos",
    label: "Download Demo",
    url: "/demos",
    Component: Download,
    menus: ["home", "buttons"],
  },
  {
    name: "Discord-Full",
    label: "Join our Discord",
    url: "https://discordapp.com/invite/5AeaZxX",
    Component: Discord,
    menus: ["buttons"],
  },
  {
    name: "Twitter",
    url: "https://twitter.com/TheTransmogrify",
    Component: Twitter,
    menus: ["main"],
  },
  {
    name: "Kickstarter",
    label: "Go to Kickstarter",
    url: "https://www.kickstarter.com/projects/952307124/transmogrify?ref=c5vynu",
    Component: Kickstarter,
    menus: ["main","buttons"],
  },
  {
    name: "Presskit",
    label: "Presskit",
    url: "https://drive.google.com/drive/u/0/folders/1jkkyRIvGEz4Y1wiFFPJONIsF2HsH3u0w",
    Component: News,
    menus: ["buttons"],
  },
].map( menuItem => menuItem.label ? menuItem : { label:menuItem.name, ...menuItem}) as IconSettings[]

const iconsIndexes: Record<string, IconSettings> = {}

icons.forEach(icon => {
  iconsIndexes[icon.name.toLowerCase()] = icon
})

export default icons
export const getMenuIcons = (name: string) => {
  return icons.filter(({ menus }) => menus.some(n => n === name))
}
export const getIcon = (name: string): IconSettings => {
  return iconsIndexes[name]
}
