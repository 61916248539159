import React from "react"
import ButtonLink from "./ButtonLink"
import { css } from "@emotion/core"

export default () => (
  <ButtonLink
    css={css`
      font-size: 2em;
    `}
    to="/transmogrify_presskit.zip"
    color="#32343b"
    background="orange"
    download={true}
  >
    Download Presskit
  </ButtonLink>
)
