import React, { useEffect } from "react"
import Modal from "react-modal"
import { css } from "@emotion/core"
import { lighten } from "polished"

interface Props {
  contentLabel: string
  children: React.ReactNode[] | React.ReactNode
  isOpen: boolean
  isSmall?: boolean
  onRequestClose: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
}

export default ({
  children,
  contentLabel,
  isOpen,
  isSmall,
  onRequestClose,
}: Props) => {
  useEffect(() => Modal.setAppElement("#___gatsby"), [])
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="modal"
      css={
        isSmall
          ? css`
              min-width: 500px;
              min-height: 300px;
            `
          : css`
              top: 40px;
              left: 40px;
              right: 40px;
              bottom: 40px;
            `
      }
      overlayClassName="overlay"
      closeTimeoutMS={300}
    >
      <div
        css={css`
          position: absolute;
          top: 1em;
          left: 1em;
          right: 1em;
          bottom: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        `}
      >
        {children}
      </div>
      <a
        css={css`
          color: #25867e;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 3em;
          font-family: sans-serif;
          cursor: pointer;
          text-align: center;
          line-height: 1em;
          width: 1em;
          height: 1em;
          background: #fdfdfd;
          border-radius: 50%;
          &:hover {
            color: ${lighten(0.3, `#25867e`)};
          }
        `}
        onClick={onRequestClose}
      >
        ×
      </a>
    </Modal>
  )
}
