import React, { SVGProps } from "react"

export default ({ fill="#ffffff" }: SVGProps<SVGGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <g
      fill={fill}
      transform="matrix(1.9403234,0,0,1.9403234,-117.75344,-96.11836)"
    >
      <path d="m 107.19922,66.599609 c 0,0 -13.699611,-0.3 -28.099611,10.5 0,0 -14.40039,26.100781 -14.40039,58.300781 0,0 8.4,14.49922 30.5,15.19922 0,0 3.701172,-4.49883 6.701171,-8.29883 C 89.200391,138.50078 84.400391,130.5 84.400391,130.5 c 0,0 0.998828,0.69922 2.798828,1.69922 0.1,0.1 0.20039,0.20078 0.40039,0.30078 0.3,0.2 0.600391,0.3 0.900391,0.5 2.5,1.4 5.000781,2.50039 7.300781,3.40039 4.1,1.6 8.999219,3.19883 14.699219,4.29883 7.5,1.4 16.30039,1.90156 25.90039,0.10156 4.7,-0.8 9.5,-2.20078 14.5,-4.30078 3.5,-1.3 7.4,-3.20039 11.5,-5.90039 0,0 -4.99961,8.20039 -18.09961,11.90039 3,3.8 6.59961,8.09961 6.59961,8.09961 22.1,-0.7 30.59961,-15.19922 30.59961,-15.19922 0,-32.2 -14.40039,-58.300781 -14.40039,-58.300781 -14.4,-10.8 -28.09961,-10.5 -28.09961,-10.5 l -1.40039,1.59961 c 17,5.2 24.90039,12.701172 24.90039,12.701172 -10.4,-5.7 -20.59961,-8.49961 -30.09961,-9.59961 -7.2,-0.8 -14.10117,-0.600781 -20.20117,0.199219 -0.6,0 -1.09922,0.09922 -1.69922,0.199219 C 107,71.999219 98.500781,73.3 87.800781,78 c -3.7,1.7 -5.90039,2.900391 -5.90039,2.900391 0,0 8.298828,-7.89961 26.298829,-13.09961 z m -2.79883,37.300781 c 5.7,0 10.29922,4.99961 10.19922,11.09961 0,6.1 -4.49922,11.09961 -10.19922,11.09961 -5.599999,0 -10.201171,-4.99961 -10.201171,-11.09961 0,-6.1 4.501172,-11.09961 10.201171,-11.09961 z m 36.5,0 c 5.7,0 10.19922,4.99961 10.19922,11.09961 0,6.1 -4.49922,11.09961 -10.19922,11.09961 -5.6,0 -10.20117,-4.99961 -10.20117,-11.09961 0,-6.1 4.50117,-11.09961 10.20117,-11.09961 z" />
    </g>
  </svg>
)
